import React, { useEffect, useState } from 'react';
import { Radio } from 'antd';
import { FormInput, IconSmallArrow, OrangeButton, IconPen, IconPaperclip, SmallButton, IconEye, IconClipboardType } from 'components';
import classNames from 'classnames';
import { COLORS } from 'utils/colors';
import DefaultAvatar from 'assets/images/default-avatar.png';
import { getRequestData } from 'api/api';
import { endpoints } from 'api/endpoints';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatDate, formatPriceWithComma, getIdFromPath } from 'utils/helpers';
import { GENDER, ROLE_TYPES, CLIENT_STATUS } from 'api/constants';

export const AdminSingleClient = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [userData, setUserData] = useState();

    useEffect(() => {
      const userID = getIdFromPath(location.pathname);
      (async () => {
        const { data } = await getRequestData(endpoints.getUser(userID), ROLE_TYPES.admin);

        if (data) {
          setUserData(data);
        }
      })();
    }, [location.pathname])

    return userData && (
      <div className='flex flex-col w-full gap-6' >
        <div className='flex justify-between w-full'>
          <div className='flex items-center gap-x-4'>
            <div onClick={() => navigate('/admin/clients')} className='w-10 h-10 bg-white rounded-lg shadow-block-shadow flex items-center justify-center cursor-pointer'>
              <IconSmallArrow className='w-2 h-4' />
            </div>
            <p className='nunito-title'>{userData.profile.firstname} {userData.profile.lastname}</p>
          </div>
          <OrangeButton onClick={() => navigate('#')} label='Edit' icon={<IconPen />} />
        </div>
        <div className='flex gap-6 items-start'>
          <div className='flex flex-col gap-[18px] base-block pt-[30px] w-[442px]'>
            <p className='nunito-title px-[30px] pb-[11px]'>Client Information</p>
            <div className={classNames('relative w-full flex flex-col pt-6 pb-[30px] gap-y-[18px] border-t border-color-lightBlue px-[30px] self-center items-center',
              'before:content-[""] before:absolute before:w-4 before:h-4 before:rounded-full before:border before:border-color-lightBlue before:bg-white before:z-10 before:top-0 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2',
            )}>
              <img className='self-center w-[190px] h-[190px] rounded-xl' src={userData.profile.avatar ? userData.profile.avatar : DefaultAvatar} alt='profile' />
              <div className='flex gap-x-[18px]'>
                <FormInput
                  label="First Name"
                  readOnly
                  value={userData.profile.firstname}
                />
                <FormInput
                  label="Last Name"
                  value={userData.profile.lastname}
                  readOnly
                />
              </div>
              <FormInput
                label="Date of Birth"
                value={formatDate(userData.profile.birthdate)}
                className='w-full'
                readOnly
              />
              <FormInput
                label="Email"
                value={userData.profile.email}
                className='w-full'
                readOnly
              />
              <FormInput
                className='w-full'
                type='number'
                label="Phone"
                prefix='+1'
                value={userData.profile.phone}
                readOnly
              />
              <div className='flex justify-between w-full p-4 rounded-lg shadow-block-shadow'>
                <div className='flex items-center gap-x-1'>
                  <IconPaperclip color={COLORS.black} />
                  <p className='text-16'>Attached Document</p>
                </div>
                <SmallButton icon={<IconEye className={!userData.client.documents[1]?.url ? 'pointer-events-none hover:text-darkGrey' : ''} color={!userData.client.documents[1]?.url ? COLORS.darkGrey : COLORS.blue} />} disabled={!userData.client?.documents[1]?.url} label='View' href={userData.client.documents[1]?.url} target="_blank" />
              </div>
              <div className='flex gap-x-4'>
                <FormInput
                  label="SLH"
                  value={userData.client?.name}
                  readOnly
                />
                <FormInput
                  label="Start Date"
                  value={formatDate(userData.client?.start_date)}
                  readOnly
                />
              </div>
              {userData.profile.gender && <div className='flex flex-col gap-y-1 self-start'>
                <p className='text-14 font-medium'>Gender*</p>
                <Radio checked className='px-4 py-2.5 bg-white border border-color-divider rounded-xl' value={userData.profile.gender}>{GENDER[userData.profile.gender]}</Radio>
              </div>}
              <FormInput
                className='w-full'
                label="Identify As"
                value={userData.profile.identifyas}
                readOnly
              />
            </div>
          </div>
          <div className='flex flex-col base-block pt-[30px] flex-1'>
            <div className='flex justify-between px-[30px] pb-[17px]'>
              <p className='nunito-title'>Progress</p>
            </div>
            <div className={classNames('relative w-full flex flex-col pt-6 pb-[30px] border-t border-color-lightBlue px-[30px] self-center items-center',
              'before:content-[""] before:absolute before:w-4 before:h-4 before:rounded-full before:border before:border-color-lightBlue before:bg-white before:z-10 before:top-0 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2',
            )}>
              <div className='flex flex-col gap-y-3 w-full'>
                <div className='flex justify-between'>
                  <p className='text-14 text-lightBlue whitespace-nowrap'>Status</p>
                  <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
                  <div className='flex items-center gap-x-3'>
                    {userData.client?.status === CLIENT_STATUS.failed && <IconEye color={COLORS.blue} />}
                    <p className='w-6 h-6 rounded-full bg-white shadow-block-shadow flex justify-center items-center'>
                      <span className={classNames('uppercase text-xl leading-5 font-medium',
                        { 'text-blue': userData.client?.status === CLIENT_STATUS.not_started || userData.client?.status === CLIENT_STATUS.in_progress },
                        { 'text-green': userData.client?.status === CLIENT_STATUS.completed },
                        { 'text-red': userData.client?.status === CLIENT_STATUS.failed },
                      )}>
                        {userData.client?.status.slice(0, 1)}
                      </span>
                    </p>
                    <p className='text-14 uppercase font-medium'>W{userData.client?.week}</p>
                  </div>
                </div>
                <div className='flex justify-between'>
                  <p className='text-14 text-lightBlue whitespace-nowrap'>Onboarding Docs</p>
                  <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
                  <p className={`text-14 line-clamp-1 max-w-[unset] text-normal ${userData.client?.onboarded ? 'text-green ' : 'text-red'}`}>{userData.client?.onboarded ? 'Completed' : 'To complete'}</p>
                </div>
                <div className='flex justify-between'>
                  <p className='text-14 text-lightBlue whitespace-nowrap'>Program Start</p>
                  <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
                  <p className={`text-14 line-clamp-1 max-w-[unset] text-normal ${userData.client?.approved ? 'text-green ' : 'text-red'}`}>{userData.client?.approved ? 'Approved' : 'Denied'}</p>
                </div>
                <div className='flex justify-between'>
                  <p className='text-14 text-lightBlue whitespace-nowrap'>Scholarship Start</p>
                  <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
                  <p className='text-14 line-clamp-1 max-w-[unset]'>{`$${formatPriceWithComma(3000)}`}</p>
                </div>
                <div className='flex justify-between'>
                  <p className='text-14 text-lightBlue whitespace-nowrap'>Scholarship Remaining</p>
                  <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
                  <p className='text-14 line-clamp-1 max-w-[unset]'>{`$${formatPriceWithComma(0)}`}</p>
                </div>
                <div className='flex justify-between'>
                  <p className='text-14 text-lightBlue whitespace-nowrap'>Projected Cost</p>
                  <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
                  <p className='text-14 line-clamp-1 max-w-[unset]'>{`$${formatPriceWithComma(0)}`}</p>
                </div>
                <div className='flex justify-between'>
                  <p className='text-14 text-lightBlue whitespace-nowrap'>Final Actual Cost</p>
                  <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
                  <p className='text-14 line-clamp-1 max-w-[unset]'>{`$${formatPriceWithComma(2928.57)}`}</p>
                </div>
                <div className='flex justify-between'>
                  <p className='text-14 text-lightBlue whitespace-nowrap'>Recovered Funding</p>
                  <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
                  <p className='text-14 line-clamp-1 max-w-[unset]'>{`$${formatPriceWithComma(71.43)}`}</p>
                </div>
              </div>
              <button className='px-6 py-2.5 bg-transparent border border-red rounded-[10px] mt-6 self-center text-red text-16 font-medium duration-150 hover:border-orange hover:text-orange' type='button'>Terminate</button>
              {userData.client?.tasks.length > 0 && <div className='flex flex-col mt-6 pt-6 w-full'>
                <p className='text-14 mb-3'>Forms</p>
                <div className='flex flex-col gap-y-4'>
                  {userData.client?.tasks.map(task => {
                    return (
                      <div className='flex items-center p-4 rounded-lg shadow-block-shadow' key={task.task_id}>
                        <IconClipboardType className='mr-1 min-w-8 min-h-8' />
                        <p className='text-16 ml-1 mr-3 flex-1'>{task.name} - <span className='whitespace-nowrap'>Week {task.week}</span></p>
                        <p className={`text-14 mr-6 ${task.completed ? 'text-green' : 'text-red'}`}>{task.completed ? 'Completed' : 'Not Completed'}</p>
                        <SmallButton
                          icon={
                            <IconEye
                              className={!task.completed ? 'pointer-events-none hover:text-darkGrey' : ''}
                              color={!task.completed ? COLORS.darkGrey : COLORS.blue}
                            />}
                          label='View'
                          href={`${userData.profile.user_id}/weekly-forms/${task.week}`}
                          disabled={!task.completed} />
                      </div>
                    )
                  })}
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    );
  };

