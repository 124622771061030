import React, { useState } from 'react';
import { Upload, Button, message } from 'antd';
import { IconPaperclip } from 'components/icons/Paperclip';
import { COLORS } from 'utils/colors';

export const FileUpload = ({onFileSelect ,className, ...restProps }) => {
  const [fileList, setFileList] = useState([]);

  const beforeUpload = (file) => {
    const isDocOrPdf = file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    if (!isDocOrPdf) {
      message.error('You can only upload PDF or DOCX files!');
      return Upload.LIST_IGNORE; // Prevent upload
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error('File must be smaller than 10MB!');
      return Upload.LIST_IGNORE; // Prevent upload
    }
    return false; // Prevent automatic upload
  };

  const handleChange = ({ file, fileList }) => {
    setFileList(fileList);

    if (file.status === 'removed') {
      onFileSelect(null); // Clear the selected file
    } else {
      onFileSelect(file); // Pass the selected file to the parent
    }
  };

  return (
    <Upload
      fileList={fileList}
      beforeUpload={beforeUpload}
      onChange={handleChange}
      onRemove={() => onFileSelect(null)}
      className={`${className}`}
      {...restProps}
    >
      <Button className='w-full border-none flex justify-between h-[44px] text-base font-medium base-block rounded-lg '>Attach a Document <IconPaperclip color={COLORS.blue}/></Button>
    </Upload>
  );
};
