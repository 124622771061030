import React, { useState } from 'react';
import { Upload, message } from 'antd';
import DefaultAvatar from 'assets/images/default-avatar.png';
import { IconUpload } from 'components/icons/Upload';
import { COLORS } from 'utils/colors';

export const CustomUploadPhoto = ({className, onImageUpload}) => {
  const [imageUrl, setImageUrl] = useState(DefaultAvatar);
  const [uploadText, setUploadText] = useState('Upload Image');

  const handleChange = info => {
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, imageUrl => {
        setImageUrl(imageUrl);
        setUploadText('Replace');
      });

      if (onImageUpload) {
        onImageUpload(info.file.originFileObj);
      }
    }
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const uploadButton = (
    <div className='flex flex-col items-center'>
      <img
        className='w-20 h-20 rounded-xl'
        src={imageUrl || DefaultAvatar}
        alt="avatar"
      />
      <div className='flex mt-3 gap-x-2'>{uploadText}<IconUpload color={COLORS.blue}/></div>
    </div>
  );

  return (
    <Upload
      name="avatar"
      listType="picture-card"
      className={`avatar-uploader ${className ? className : ''}`}
      showUploadList={false}
      action={imageUrl}
      beforeUpload={file => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
      }}
      onChange={handleChange}
    >
      {uploadButton}
    </Upload>
  );
};
