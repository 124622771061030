import React, { useEffect, useState } from 'react';
import { endpoints } from 'api/endpoints';
import { getRequestData } from 'api/api';
import { AssociationForSoberLiving } from './components/associationForSoberLiving';
import { Beds } from './components/beds';
import { ClientAnalytics, ScholarshipsAnalytics } from 'components';
import { ROLE_TYPES } from 'api/constants';

export const ManagerDashboard = () => {
  const [dashboardData, setDashboardData] = useState();

  useEffect(() => {
    (async () => {
      const { data } = await getRequestData(endpoints.getDashboard, ROLE_TYPES.slh_manager);
      setDashboardData(data);
    })();
  }, [])

  return dashboardData && (
    <div className="flex flex-col w-full gap-y-6">
      <div className='flex gap-x-6 '>
        <AssociationForSoberLiving />
        <Beds/>
      </div>
      <ClientAnalytics clients={dashboardData.clients} seeAllUrl='/manager/clients'/>
      <ScholarshipsAnalytics scholarships={dashboardData.scolarships} detailsUrl='/manager/finances'/>
    </div>
  );
}

