import React from 'react';
import { IconArrow, IconClientFailure, IconClients, IconClientSuccess, OrangeButton } from 'components';
import classNames from 'classnames';
import { COLORS } from 'utils/colors';
import { useLocation, useNavigate } from 'react-router-dom';

export const ClientAnalytics = ({ clients, seeAllUrl }) => {
    const navigate = useNavigate();
    const location = useLocation();

    return clients && (
        <div className='flex flex-col px-[30px] pt-[30px] base-block w-full'>
            <div className='flex justify-between items-center mb-8'>
                <p className='nunito-title'>{location.pathname.includes('manager') ? 'PF25 Clients' : 'Clients'}</p>
                <OrangeButton onClick={() => navigate(seeAllUrl)} type='button' label='See All' />
            </div>
            <div className='flex border-t border-divider'>
                <div className='flex items-start justify-center flex-1 pb-[30px] pt-4'>
                    <div className='bg-yellow rounded-full w-[84px] h-[84px] flex items-center justify-center'>
                        <IconClients className='w-10 h-10' color={COLORS.orange} />
                    </div>
                    <div className='flex flex-col ml-5'>
                        <p className='text-14 text-lightBlue'>New Clients</p>
                        <p className='text-24 text-black my-1'>{clients.new.total}</p>
                        <div className='flex items-center'>
                        {clients.new.this_month_activity === '0' ? <span className='text-lightBlue text-12'>-</span> : <IconArrow color={clients.new.this_month_activity < '0' ? COLORS.red : COLORS.green} className={clients.new.this_month_activity < '0' ? 'rotate-180' : ''} />}
                            <p className={`text-12 ml-1 ${clients.new.this_month_activity === '0' ? 'text-lightBlue' : clients.new.this_month_activity < '0' ? 'text-red' : 'text-green'}`}>{clients.new.this_month_activity}% <span className='text-black'>this month</span></p>
                        </div>
                    </div>
                </div>
                <div className={classNames('flex items-start justify-center flex-1 pb-[30px] pt-4 relative border-r border-l border-divider',
                    'before:content-[""] before:absolute before:w-4 before:h-4 before:rounded-full before:border before:border-color-lightBlue before:bg-white before:z-10 before:top-1/2 before:left-0 before:-translate-x-1/2 before:-translate-y-1/2',
                    'after:content-[""] after:absolute after:w-4 after:h-4 after:rounded-full after:border after:border-color-lightBlue after:bg-white after:z-10 after:top-1/2 after:right-0 after:translate-x-1/2 after:-translate-y-1/2',)}>
                    <div className='bg-lightGreen rounded-full w-[84px] h-[84px] flex items-center justify-center'>
                        <IconClientSuccess className='w-10 h-10' color={COLORS.green} />
                    </div>
                    <div className='flex flex-col ml-5'>
                        <p className='text-14 text-lightBlue'>Completed Clients</p>
                        <p className='text-24 text-black my-1'>{clients.successful.total}</p>
                        <div className='flex items-center'>
                            {clients.successful.this_month_activity === '0' ? <span className='text-lightBlue text-12'>-</span> : <IconArrow color={clients.successful.this_month_activity < '0' ? COLORS.red : COLORS.green} className={clients.successful.this_month_activity < '0' ? 'rotate-180' : ''} />}
                            <p className={`text-12 ml-1 ${clients.successful.this_month_activity === '0' ? 'text-lightBlue' : clients.successful.this_month_activity < '0' ? 'text-red' : 'text-green'}`}>{clients.successful.this_month_activity}% <span className='text-black'>this month</span></p>
                        </div>
                    </div>
                </div>
                <div className='flex items-start justify-center flex-1 pb-[30px] pt-4'>
                    <div className='bg-pink rounded-full w-[84px] h-[84px] flex items-center justify-center'>
                        <IconClientFailure className='w-10 h-10' color={COLORS.red} />
                    </div>
                    <div className='flex flex-col ml-5'>
                        <p className='text-14 text-lightBlue'>Early Departures</p>
                        <p className='text-24 text-black my-1'>{clients.failed.total}</p>
                        <div className='flex items-center'>
                            {clients.failed.this_month_activity === '0' ? <span className='text-lightBlue text-12'>-</span> : <IconArrow color={clients.failed.this_month_activity < '0' ? COLORS.red : COLORS.green} className={clients.failed.this_month_activity < '0' ? 'rotate-180' : ''} />}
                            <p className={`text-12 ml-1 ${clients.failed.this_month_activity === '0' ? 'text-lightBlue' : clients.failed.this_month_activity < '0' ? 'text-red' : 'text-green'}`}>{clients.failed.this_month_activity}% <span className='text-black'>this month</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

