import React from 'react';
import { Table } from 'antd';
import { IconSmallArrow } from 'components';

export const CustomTable = ({ columns, data, pageCount, current, pageSize, onChange, wrapperClassName, ...props }) => {

  const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return <div className='w-10 h-10 bg-white rounded-lg shadow-block-shadow flex items-center justify-center cursor-pointer'>
        <IconSmallArrow className='w-2 h-4' />
      </div>;
    }
    if (type === 'next') {
      return <div className='w-10 h-10 bg-white rounded-lg shadow-block-shadow flex items-center justify-center cursor-pointer'>
        <IconSmallArrow className='w-2 h-4 rotate-180' />
      </div>;
    }
    return originalElement;
  }

  return (
    <Table
      {...props}
      className={wrapperClassName ? wrapperClassName : 'p-[30px] base-block w-full'}
      columns={columns}
      dataSource={data}
      showSorterTooltip={{
        target: 'sorter-icon',
      }}
      pagination={ pageCount > 1 ? {
        itemRender: itemRender,
        defaultCurrent: 1,
        onChange: onChange,
        pageSize: pageSize,
        total: pageCount * pageSize,
        current: current,
        defaultPageSize: false,
        showSizeChanger: false,
        position: ['bottomCenter'],
      } : false}
      locale={{
        sortAscend: <IconSmallArrow />,
        sortDescend: <IconSmallArrow className='rotate-180' />,
      }}
    />
  );
}

