import { API_BASE_URL } from 'api/constants';
import { buildURL } from 'utils/helpers';

export const endpoints = {
  //GLOBAL
  getDashboard: `${API_BASE_URL}/api/admin/me/dashboard/`,
  getUserProfile: `${API_BASE_URL}/api/admin/me/profile`,
  getClients:(params) => buildURL(API_BASE_URL, '/api/admin/clients/list', params),
  adminLogin: `${API_BASE_URL}/api/admin/auth/login`,
  getFinancesSummary: `${API_BASE_URL}/api/admin/finances/summary`,
  getFinancesClients: (params) => buildURL(API_BASE_URL, '/api/admin/finances/clients', params),
  getPartner: (partner_id) => `${API_BASE_URL}/api/partner/${partner_id}`,
  getUser: (user_id) => `${API_BASE_URL}/api/admin/${user_id}/details`,
  terminateUser: (user_id) => `${API_BASE_URL}/api/admin/${user_id}/terminate`,
  getUserTasks: (user_id,week_id) => `${API_BASE_URL}/api/admin/${user_id}/forms?week=${week_id}`,

  // ADMIN
  registerClient: `${API_BASE_URL}/api/client/register`,
  getForms: `${API_BASE_URL}/api/common/forms`,
  uploadAvatar: (userID) => `${API_BASE_URL}/api/admin/${userID}/upload-image`,
  uploadDocument: (userID) => `${API_BASE_URL}/api/admin/${userID}/upload-document`,
  getPartners: `${API_BASE_URL}/api/partner/list`,
  registerPartner: `${API_BASE_URL}/api/partner`,
  updatePartner: (partner_id) => `${API_BASE_URL}/api/partner/${partner_id}`,
  getFinancesPartners: `${API_BASE_URL}/api/admin/finances/partners`,
  
  // CLIENT
  clientLogin: `${API_BASE_URL}/api/auth/login`,
  clientProfile: `${API_BASE_URL}/api/client/me/profile`,
  clientDashboard: `${API_BASE_URL}/api/client/me/dashboard`,
  clientSubmitFeedback: `${API_BASE_URL}/api/client/feedback`,
  getClientFormsByWeek: (week) => `${API_BASE_URL}/api/client/me/forms?week=${week}`,
};
