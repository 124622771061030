import React, { useEffect, useState } from 'react';
import { getRequestData } from 'api/api';
import { endpoints } from 'api/endpoints';
import { FinancesSinglePartner } from './financesSinglePartner';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/scrollbar';

export const FinancesPartners = ({domain}) => {
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await getRequestData(endpoints.getFinancesPartners, domain);
      if (data) {
        setPartners(data);
      }
    })();
  }, [domain])

  return (
    <div className='flex w-full mb-6'>
      <Swiper
        className='!py-6 w-full'
        spaceBetween={16}
        slidesPerView='auto'
        scrollbar={{
          hide: false,
          draggable:true,
          horizontalClass:'!bg-white',
          dragClass: 'swiper-scrollbar-drag !bg-grey'
        }}
        modules={[Scrollbar]}
      >
        {partners.map((partner,index) => {
          return (
            <SwiperSlide className='!w-[auto]' key={`${partner.name}-${index}`}>
              <FinancesSinglePartner {...partner} />
            </SwiperSlide>
          )
        })}
      </Swiper>

    </div>
  )
};

