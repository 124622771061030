import React from 'react';
import classNames from 'classnames';
import { IconBed, IconPen, OrangeButton } from 'components';
import { COLORS } from 'utils/colors';

export const Beds = () => {
  const totalBeds = 48;
  const availableBeds = 20;

  return (
    <div className='flex flex-col base-block pt-[30px] flex-1'>
      <div className='flex items-center justify-between px-[30px] mb-[17px]'>
        <p className='nunito-title'>Beds</p>
        <OrangeButton icon={<IconPen />} label='Edit' />
      </div>
      <div className={classNames('relative w-full flex items-start pt-8 pb-[30px] gap-x-2.5 border-t border-color-lightBlue px-[30px]',
        'before:content-[""] before:absolute before:w-4 before:h-4 before:rounded-full before:border before:border-color-lightBlue before:bg-white before:z-10 before:top-0 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2',
      )}>
        <div className='flex flex-col gap-y-6'>
          <div className='flex items-center gap-x-4'>
            <div className='bg-lightGreen rounded-full w-[84px] h-[84px] flex items-center justify-center'>
              <IconBed className='w-10 h-10' color={COLORS.green} />
            </div>
            <div className='flex flex-col gap-y-1'>
              <p className='text-14 text-lightBlue'>Total</p>
              <p className='text-24'>48</p>
            </div>
          </div>
          <div className='flex items-center gap-x-4'>
            <div className='bg-lightGreen rounded-full w-[84px] h-[84px] flex items-center justify-center'>
              <IconBed className='w-10 h-10' color={COLORS.green} />
            </div>
            <div className='flex flex-col gap-y-1'>
              <p className='text-14 text-lightBlue'>Available</p>
              <p className='text-24'>20</p>
            </div>
          </div>
          <div className='flex items-center gap-x-4'>
            <div className='bg-lightGreen rounded-full w-[84px] h-[84px] flex items-center justify-center'>
              <IconBed className='w-10 h-10' color={COLORS.green} />
            </div>
            <div className='flex flex-col gap-y-1'>
              <p className='text-14 text-lightBlue'>Busy</p>
              <p className='text-24'>28</p>
            </div>
          </div>
        </div>
        <div className='flex flex-wrap gap-2 max-h-[328px] scrollbar scrollbar-thumb-sky-700 scrollbar-track-sky-300  overflow-y-auto'>
          {Array.from({ length: totalBeds * 2}).map((_, index) => (
            <div className={` ${index + 1 > availableBeds ? 'bg-pink' : 'bg-lightGreen'} rounded-full w-10 h-10 flex items-center justify-center`} key={`bed-${index}`}>
              <IconBed color={index + 1 > availableBeds ? COLORS.red : COLORS.green} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

