import React from 'react';
import { Pagination as AntdPagination } from 'antd';

export const Pagination = ({ defaultPageSize = 6, defaultCurrent = 1, pageSize = 6, showQuickJumper, pageCount, ...props }) => {
  if (pageCount <= 1) return null;

  return (
    <AntdPagination
      className='mt-6'
      align='center'
      showQuickJumper={showQuickJumper}
      defaultCurrent={defaultCurrent}
      defaultPageSize={defaultPageSize}
      pageSize={pageSize}
      total={10}
      {...props}
    />
  );
}

