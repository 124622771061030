import React, { useCallback, useEffect, useState } from 'react';
import { getRequestData } from 'api/api';
import { endpoints } from 'api/endpoints';
import { IconFilter, IconSearch, IconSmallArrow, Pagination, SelectOption } from 'components';
import classNames from 'classnames';
import { Input } from 'antd';
import { COLORS } from 'utils/colors';
import { SingleClientRow } from './singleClientRow';
import { ROLE_TYPES } from 'api/constants';

export const FinancesClients = ({ domain }) => {
  const [clients, setClients] = useState();

  const fetchClients = useCallback(async (params) => {
    const { data } = await getRequestData(endpoints.getFinancesClients(params), domain);
    if (data) setClients(data);
  }, [domain])

  useEffect(() => {
    fetchClients({ page: 1, pageSize: 10 })
  }, [fetchClients])

  const storeClients = useCallback(() => {
    return clients.results?.map((client) => <SingleClientRow client={client} key={client.client_id} />)
  }, [clients?.results])

  return clients && (
    <div className='flex flex-col base-block pt-[30px]'>
      <div className={classNames('flex justify-between px-[30px] pb-6 relative border-b border-color-lightBlue',
        'before:content-[""] before:absolute before:w-4 before:h-4 before:rounded-full before:border before:border-color-lightBlue before:bg-white before:z-10 before:top-full before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2')}>
        <p className='nunito-title'>{domain === ROLE_TYPES.slh_manager ? 'PF25 Clients' : 'Clients'}</p>
        <Input
          className='search w-[300px] text-lightBlue text-16 rounded-ten bg-light'
          type='text'
          placeholder="Search"
          addonBefore={<IconSearch color={COLORS.lightBlue} />}
        // onSearch={onSearch}
        />
      </div>
      <div className='flex items-center gap-x-3 px-[30px] mt-6'>
        <div className='flex items-center gap-x-1 mr-3'>
          <IconFilter />
          <p className='text-16'>Filter</p>
        </div>
        <SelectOption
          // onSelect={(value) => handleSLHSelectChange(value)}
          dropdownStyle={{ padding: '16px', background: 'white' }}
          required
          suffixIcon={<IconSmallArrow color={COLORS.black} className='rotate-[-90deg] w-3 h-3' />}
          className='h-[40px] text-base font-medium base-block rounded-lg'
          placeholder='Status'
          dropdownRender={(originalNode) => originalNode}
          options={[{ label: 'In Progress', value: 1 }, { label: 'Completed', value: 2 }, { label: 'Failed', value: 3 }, { label: 'Not Started', value: 4 }]}
        />
      </div>
      <div className='flex flex-col p-[30px] gap-y-3'>
        <div className='flex items-center text-lightBlue'>
          <p className='text-14 w-[25%]'>{domain === ROLE_TYPES.slh_manager ? 'PF25 Client' : 'Client'}</p>
          <p className='text-14 w-[28%]'>Home</p>
          <p className='text-14 w-[16.5%]'>Status</p>
          <p className='text-14 w-[10%]'>Start</p>
          <p className='text-14 w-[9.5%]'>Granted</p>
          <p className='text-14 w-[10%]'>Remaining</p>
        </div>
        {storeClients()}
        <Pagination
          className='self-center'
          onChange={page => fetchClients({ page })}
          pageCount={clients.pageCount}
          pageSize={clients.pageSize}
          total={clients.count}
        />
      </div>
    </div>
  )
};

