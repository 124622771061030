import React from 'react';
import { OrangeButton } from 'components';
import { FinancesSummary, FinancesClients } from 'components';
import { ROLE_TYPES } from 'api/constants';

export const ManagerFinances = () => {

  return (
    <div className="flex flex-col w-full gap-y-6">
      <div className='flex justify-between'>
        <p className='nunito-title uppercase'>Finances</p>
        <OrangeButton label='View invoice' />
      </div>
      <FinancesSummary isManager />
      <FinancesClients domain={ROLE_TYPES.slh_manager} />
    </div>
  );
}
