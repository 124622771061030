import React from 'react';
import classNames from 'classnames';

export const AssociationForSoberLiving = () => {

  return  (
    <div className='flex flex-col gap-[18px] base-block pt-[30px] w-[450px]'>
      <p className='nunito-title px-[30px] pb-[11px]'>Association For Sober living</p>
      <div className={classNames('relative w-full flex flex-col pt-6 pb-[30px] gap-y-[18px] border-t border-color-lightBlue px-[30px] self-center items-center',
        'before:content-[""] before:absolute before:w-4 before:h-4 before:rounded-full before:border before:border-color-lightBlue before:bg-white before:z-10 before:top-0 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2',
      )}>
        <div className='flex flex-col gap-y-3 w-full'>
          <div className='flex  justify-between'>
            <p className='text-14 text-lightBlue whitespace-nowrap'>Classification</p>
            <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
            <p className='text-14 line-clamp-1 max-w-[unset]'>1</p>
          </div>
          <div className='flex  justify-between'>
            <p className='text-14 text-lightBlue whitespace-nowrap'>Weekly Rate</p>
            <span className='flex-1  border-b border-dashed border-color-divider min-w-3' />
            <p className='text-14 line-clamp-1 max-w-[unset]'>$500.00</p>
          </div>
          <div className='flex justify-between'>
            <p className='text-14 text-lightBlue whitespace-nowrap'>Parent</p>
            <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
            <p className='text-14 line-clamp-1 max-w-[unset]'>Sober Homes USA</p>
          </div>
          <div className='flex justify-between'>
            <p className='text-14 text-lightBlue whitespace-nowrap'>Gender</p>
            <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
            <p className='text-14 line-clamp-1 max-w-[unset]'>Male, Female, Other</p>
          </div>
          <div className='flex justify-between'>
            <p className='text-14 text-lightBlue whitespace-nowrap'>Region</p>
            <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
            <p className='text-14 line-clamp-1 max-w-[unset]'>Massachussets</p>
          </div>
          <div className='flex justify-between'>
            <p className='text-14 text-lightBlue whitespace-nowrap'>Address</p>
            <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
            <p className='text-14 line-clamp-1 max-w-[unset]'>Some address</p>
          </div>
          <div className='flex justify-between'>
            <p className='text-14 text-lightBlue whitespace-nowrap'>Website</p>
            <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
            <p className='text-14 line-clamp-1 max-w-[unset]'>example.com</p>
          </div>
          <div className='flex justify-between'>
            <p className='text-14 text-lightBlue whitespace-nowrap'>Manager Phone</p>
            <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
            <p className='text-14 line-clamp-1 max-w-[unset]'>+0987654321</p>
          </div>
          <div className='flex justify-between'>
            <p className='text-14 text-lightBlue whitespace-nowrap'>Manager Email</p>
            <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
            <p className='text-14 line-clamp-1 max-w-[unset]'>manageremail@gmail.com</p>
          </div>
          <div className='flex justify-between'>
            <p className='text-14 text-lightBlue whitespace-nowrap'>Supervisor Phone</p>
            <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
            <p className='text-14 line-clamp-1 max-w-[unset]'>+0987654321</p>
          </div>
          <div className='flex justify-between'>
            <p className='text-14 text-lightBlue whitespace-nowrap'>Supervisor Email</p>
            <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
            <p className='text-14 line-clamp-1 max-w-[unset]'>Supervisoremail@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  );
}

