import React, { useState } from 'react';
import { CustomCollapse, IconArrow, IconBed, IconClientFailure, IconClientSuccess, IconPen, IconSoberLiving, IconTwoUser, IconWallet, SmallButton } from "components";
import { COLORS } from 'utils/colors';
import classNames from 'classnames';
import { formatPriceWithComma, getBaseUrl } from 'utils/helpers';
import { Link } from 'react-router-dom';

export const SLHCard = ({ parentClassName, ...props }) => {
  const [activeKey, setActiveKey] = useState(false);
  
  return (
    <div className={`w-[calc(33.33%-32px/3)] duration-150 ${activeKey && parentClassName ? 'pb-[300px]' : ''}`}>
      <div className={`flex flex-col relative base-block pb-4 ${activeKey ? 'rounded-t-[30px] rounded-b-none' : 'rounded-[30px] delay-100'}`}>
        <div className={classNames('flex flex-col flex-1 p-4 pb-1 min-h-[117px] border-b border-color-divider relative gap-y-1',
          'after:content-[""] after:absolute after:w-4 after:h-4 after:rounded-full after:border after:border-color-divider after:bg-white after:z-10 after:bottom-0 after:left-1/2 after:-translate-x-1/2 after:translate-y-1/2',
        )}>
          <div className='flex items-start gap-x-3'>
            <IconSoberLiving />
            <p className='nunito-title text-base leading-6 line-clamp-2	flex-1'>{props.name}</p>
            <Link to={`/admin/partner/${props.id}`} className='w-8 h-8 bg-light p-1 rounded-lg'>
              <IconPen color={COLORS.blue}/>
            </Link>
          </div>
          <p className='text-14 text-lightBlue text-ellipsis whitespace-nowrap pr-6 overflow-hidden'>{props.address}</p>
          <div className='flex gap-x-1'>
            {props.gender?.split(',').map((gender, index) => {
              return (
                <span key={`${gender}-${index}`} className='text-blue uppercase text-14'>{gender}</span>
              )
            })}
          </div>
          <span className='absolute right-0 bottom-3 px-3.5 py-2.5 text-14 font-medium border border-divider bg-light rounded-l-[20px]'>{props.rank}</span>
        </div>
        <div className='flex flex-col px-4 py-6 gap-y-2'>
          <div className='flex justify-between shadow-block-shadow-light p-1 bg-white rounded-[10px]'>
            <div className='flex items-center gap-x-1'>
              <IconTwoUser />
              <p className='text-14 text-lightBlue'>Active Clients</p>
            </div>
            <div className='flex bg-light px-3 py-1 rounded-md gap-x-1'>
              <b className='text-14'>12</b>
              <div className='flex items-center gap-x-1'>
                <IconArrow />
                <b className='text-12 text-green'>16%</b>
              </div>
            </div>
          </div>
          <div className='flex justify-between shadow-block-shadow-light p-1 bg-white rounded-[10px]'>
            <div className='flex items-center gap-x-1'>
              <IconClientSuccess color={COLORS.green} />
              <p className='text-14 text-lightBlue'>Successful Clients</p>
            </div>
            <div className='flex bg-light px-3 py-1 rounded-md gap-x-2'>
              <b className='text-14'>48</b>
              <div className='flex items-center gap-x-1'>
                <IconArrow />
                <b className='text-12 text-green'>5%</b>
              </div>
            </div>
          </div>
          <div className='flex justify-between shadow-block-shadow-light p-1 bg-white rounded-[10px]'>
            <div className='flex items-center gap-x-1'>
              <IconClientFailure color={COLORS.red} />
              <p className='text-14 text-lightBlue'>Early Departures</p>
            </div>
            <div className='flex bg-light px-3 py-1 rounded-md gap-x-2'>
              <b className='text-14'>9</b>
              <div className='flex items-center gap-x-1'>
                <IconArrow />
                <b className='text-12 text-green'>1%</b>
              </div>
            </div>
          </div>
          <div className='flex justify-between shadow-block-shadow-light p-1 bg-white rounded-[10px]'>
            <div className='flex items-center gap-x-1'>
              <IconBed />
              <p className='text-14 text-lightBlue'>Beds</p>
            </div>
            <div className='flex bg-light px-3 py-1 rounded-md gap-x-1'>
              <b className='text-14'>12 <span className='text-lightBlue'>({props.bed_count})</span></b>
            </div>
          </div>
          <div className='flex justify-between shadow-block-shadow-light p-1 bg-white rounded-[10px]'>
            <div className='flex items-center gap-x-1'>
              <IconWallet color={COLORS.green} />
              <p className='text-14 text-lightBlue'>Weekly Rate</p>
            </div>
            <div className='flex bg-light px-3 py-1 rounded-md gap-x-1'>
              <b className='text-14'>${props.weekly_rate}</b>
            </div>
          </div>
          <div className='flex justify-between shadow-block-shadow-light p-1 bg-white rounded-[10px]'>
            <div className='flex items-center gap-x-1'>
              <IconWallet color={COLORS.green} />
              <p className='text-14 text-lightBlue'>Total Granted</p>
            </div>
            <div className='flex bg-light px-3 py-1 rounded-md gap-x-1'>
              <b className='text-14'>${formatPriceWithComma(84500)}</b>
            </div>
          </div>
        </div>
        <SmallButton onClick={() => setActiveKey(!activeKey)}
          className='mx-auto w-fit before:content-[""] before:absolute before:left-4 before:bottom-8 before:right-4 before:h-[1px] before:bg-divider'
          label={activeKey ? 'Show Less' : 'Show More'} />
        <CustomCollapse isOpen={activeKey} className='absolute top-full left-0 right-0 bg-white z-50 translate-y-[-1px] rounded-b-[30px]'>
          <div className='flex flex-col gap-y-3 p-4 pt-0 shadow-no-top-shadow rounded-b-[30px]'>
            <div className='flex  justify-between'>
              <p className='text-14 text-lightBlue whitespace-nowrap'>Parent</p>
              <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
              <p className='text-14 line-clamp-1 max-w-[unset]'>{props.parent}</p>
            </div>
            <div className='flex  justify-between'>
              <p className='text-14 text-lightBlue whitespace-nowrap'>Region</p>
              <span className='flex-1  border-b border-dashed border-color-divider min-w-3' />
              <p className='text-14 line-clamp-1 max-w-[unset]'>{props.region}</p>
            </div>
            <div className='flex justify-between'>
              <p className='text-14 text-lightBlue whitespace-nowrap'>Website</p>
              <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
              <p className='text-14 line-clamp-1 max-w-[unset]'>{getBaseUrl(props.website)}</p>
            </div>
            <div className='flex justify-between'>
              <p className='text-14 text-lightBlue whitespace-nowrap'>Manager</p>
              <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
              <p className='text-14 line-clamp-1 max-w-[unset]'>{props.manager_firstname}</p>
            </div>
            <div className='flex justify-between'>
              <p className='text-14 text-lightBlue whitespace-nowrap'>Manager Phone</p>
              <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
              <p className='text-14 line-clamp-1 max-w-[unset]'>{props.manager_phone}</p>
            </div>
            <div className='flex justify-between'>
              <p className='text-14 text-lightBlue whitespace-nowrap'>Manager Email</p>
              <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
              <p className='text-14 line-clamp-1 max-w-[unset]'>{props.manager_email}</p>
            </div>
            <div className='flex justify-between'>
              <p className='text-14 text-lightBlue whitespace-nowrap'>Superviser</p>
              <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
              <p className='text-14 line-clamp-1 max-w-[unset]'>{props.supervisor_name}</p>
            </div>
            <div className='flex justify-between'>
              <p className='text-14 text-lightBlue whitespace-nowrap'>Supervisor Phone</p>
              <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
              <p className='text-14 line-clamp-1 max-w-[unset]'>{props.supervisor_phone}</p>
            </div>
            <div className='flex justify-between'>
              <p className='text-14 text-lightBlue whitespace-nowrap'>Supervisor Email</p>
              <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
              <p className='text-14 line-clamp-1 max-w-[unset]'>{props.supervisor_email}</p>
            </div>
          </div>
        </CustomCollapse>
      </div>
    </div>
  );
};
