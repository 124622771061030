import axios from 'axios';
import { endpoints } from './endpoints';
import { getAuthToken } from 'utils/helpers';
import { ROLE_TYPES } from './constants';

//Fetch data 
export const getRequestData = async (url, domain, onSuccess) => {
  const token = getAuthToken(domain);

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    
    if (onSuccess) {
      onSuccess(response.data);
    }
    return { data: response.data, error: null };
  } catch (error) {
    console.error('Error fetching data:', error);
    return { data: null, error };
  }
};

// Update user image
export const updateUserImage = (userID, image, domain) => {
  const token = getAuthToken(domain);
  const formData = new FormData();
  formData.append('image', image);

  axios.post(
    endpoints.uploadAvatar(userID),
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      },
    }
  ).then((response) => {
    console.log(response, 'image upload response');
  }).catch((error) => {
    console.log(error, 'image upload error');
  });
};

// Upload external document
export const uploadDocument = (userID, document, domain) => {
  const token = getAuthToken(domain);
  const formData = new FormData();
  formData.append('doc', document);

  return axios.post(
    endpoints.uploadDocument(userID),
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    }
  )
    .then((response) => {
      console.log(response, 'document upload response');
      return response.data;
    })
    .catch((error) => {
      console.log(error, 'document upload error');
      throw error;
    });
};

//Create partner
export const createPartner = async (formData, onSuccess) => {
  const token = getAuthToken(ROLE_TYPES.admin);
  try {
    const response = await axios.post(endpoints.registerPartner, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    console.error('Registration error:', error);
    throw error;
  }
};
//Update partner
export const updatePartner = async (formData, userID, onSuccess) => {
  const token = getAuthToken(ROLE_TYPES.admin);
  try {
    const response = await axios.put(endpoints.updatePartner(userID), formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    console.error('Registration error:', error);
    throw error;
  }
};

//Create client
export const createClient = async (formData, domain, onSuccess) => {
  const token = getAuthToken(domain);

  try {
    const response = await axios.post(endpoints.registerClient, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    console.error('Registration error:', error);
    throw error;
  }
};
//Terminate client
export const terminateClient = async (userID, formData, domain, onSuccess) => {
  const token = getAuthToken(domain);
  try {
    const response = await axios.post(endpoints.terminateUser(userID), formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    console.error('Registration error:', error);
    throw error;
  }
};
//Submit client feedback
export const clientSubmitFeedback = async (formData, domain, onSuccess) => {
  const token = getAuthToken(domain);
  try {
    const response = await axios.post(endpoints.clientSubmitFeedback, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    console.error('Registration error:', error);
    throw error;
  }
};